<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="isShowModal"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>{{ $t('confirmModal.title') }}</v-toolbar>
            <v-card-text>
              <div class="text-h6 pa-8">{{ title }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="error" @click="onCloseModal(dialog.value = false)">{{ $t('confirmModal.btn.close') }}</v-btn>
              <v-btn color="primary" @click="onConfirm">{{ $t('confirmModal.btn.confirm') }}</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data: () => ({
    isShowModal: false,
  }),
  methods: {
    onConfirm: function() {
      this.isShowModal = false;
      this.$emit("confirm");
    },
    onShowModal: function() {
      this.isShowModal = true;
    },
    onCloseModal: function() {
      this.isShowModal = false;
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
